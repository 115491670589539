import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { Form, H4, PrimaryButton, ListingImage } from '../../../components';
import { FieldAddImage } from '../../EditListingPage/EditListingWizard/EditListingPhotosPanel/EditListingPhotosForm';
import { capitalize } from '../../../util/string';

import css from './IdentityVerificationForm.module.css';

export const IDENTITY_VERIFICATION_STATE = {
  UNVERIFIED: 'unverified',
  PENDING: 'pending',
  REJECTED: 'rejected',
  ACCEPTED: 'accepted',
};
const VERIFICATION_REQUESTING_STATES = [
  IDENTITY_VERIFICATION_STATE.UNVERIFIED,
  IDENTITY_VERIFICATION_STATE.REJECTED,
];

const fileUploadProps = {
  accept: 'image/*',
  label: (
    <span className={css.chooseImageText}>
      <span className={css.chooseImage}>
        <FormattedMessage id="IdentityVerificationForm.chooseImage" />
      </span>
      <span className={css.imageTypes}>
        <FormattedMessage id="IdentityVerificationForm.imageTypes" />
      </span>
    </span>
  ),
  type: 'file',
};

const IdentityVerificationFormComponent = props => {
  const { rootClassName, className, currentUser, inProgress, ready, error, ...rest } = props;

  const { identityVerification } = currentUser?.attributes?.profile?.metadata || {};
  const { state = IDENTITY_VERIFICATION_STATE.UNVERIFIED, rejectionReason } =
    identityVerification || {};

  return (
    <FinalForm
      {...rest}
      render={({ handleSubmit, form, invalid, values }) => (
        <Form className={classNames(rootClassName || css.root, className)} onSubmit={handleSubmit}>
          {state === IDENTITY_VERIFICATION_STATE.UNVERIFIED ? (
            <p>
              <FormattedMessage id="IdentityVerificationForm.description" />
            </p>
          ) : null}

          <div className={css.wrapper}>
            <div>
              <p>
                <FormattedMessage id={`IdentityVerificationForm.description${capitalize(state)}`} />
              </p>
              <span
                className={classNames({
                  [css.unverified]: state === IDENTITY_VERIFICATION_STATE.UNVERIFIED,
                  [css.pending]: state === IDENTITY_VERIFICATION_STATE.PENDING,
                  [css.rejected]: state === IDENTITY_VERIFICATION_STATE.REJECTED,
                  [css.verified]: state === IDENTITY_VERIFICATION_STATE.ACCEPTED,
                })}
              >
                <FormattedMessage id={`IdentityVerificationForm.state${capitalize(state)}`} />
              </span>
              {state === IDENTITY_VERIFICATION_STATE.REJECTED && rejectionReason ? (
                <div className={css.rejectionReason}>
                  <div>
                    <b>
                      <FormattedMessage id="IdentityVerificationForm.rejectionReason" />
                    </b>
                  </div>
                  <div>
                    <q>
                      <i>{rejectionReason}</i>
                    </q>
                  </div>
                </div>
              ) : null}
            </div>
            {VERIFICATION_REQUESTING_STATES.includes(state) ? (
              <>
                <div>
                  <H4>
                    <FormattedMessage id="IdentityVerificationForm.titleStep1" />
                  </H4>
                  <p>
                    <FormattedMessage id="IdentityVerificationForm.descriptionStep1" />
                  </p>
                  <div className={css.documents}>
                    {values.identityFront ? (
                      <ListingImage
                        id="identityFront"
                        rootClassName={css.imageFromFile}
                        savedImageAltText="Front"
                        onRemoveImage={e => form.change('identityFront', null)}
                        image={{ imageId: values.identityFront.name, file: values.identityFront }}
                      />
                    ) : (
                      <FieldAddImage
                        {...fileUploadProps}
                        id="identityFront"
                        name="identityFront"
                        formApi={form}
                        className={{ [css.hidden]: !!values.identityFront }}
                      />
                    )}
                    {values.identityBack ? (
                      <ListingImage
                        id="identityBack"
                        rootClassName={css.imageFromFile}
                        savedImageAltText="Back"
                        onRemoveImage={e => form.change('identityBack', null)}
                        image={{ imageId: values.identityBack.name, file: values.identityBack }}
                      />
                    ) : (
                      <FieldAddImage
                        {...fileUploadProps}
                        id="identityBack"
                        name="identityBack"
                        formApi={form}
                        className={{ [css.hidden]: !!values.identityBack }}
                      />
                    )}
                  </div>
                  <p className={css.extraInfo}>
                    <FormattedMessage id="IdentityVerificationForm.safetyNote" />
                  </p>
                </div>
                <div>
                  <H4>
                    <FormattedMessage id="IdentityVerificationForm.titleStep2" />
                  </H4>
                  <p>
                    <FormattedMessage id="IdentityVerificationForm.descriptionStep2" />
                  </p>
                  <div className={css.documents}>
                    {values.selfie ? (
                      <ListingImage
                        id="selfie"
                        rootClassName={css.imageFromFile}
                        savedImageAltText="Back"
                        onRemoveImage={e => form.change('selfie', null)}
                        image={{ imageId: values.selfie.name, file: values.selfie }}
                      />
                    ) : (
                      <FieldAddImage
                        {...fileUploadProps}
                        id="selfie"
                        name="selfie"
                        formApi={form}
                        className={{ [css.hidden]: !!values.selfie }}
                      />
                    )}
                  </div>
                  <p className={css.extraInfo}>
                    <FormattedMessage id="IdentityVerificationForm.safetyNote" />
                  </p>
                </div>
              </>
            ) : null}
          </div>

          {VERIFICATION_REQUESTING_STATES.includes(state) ? (
            <div className={css.bottomWrapper}>
              {error && <FormattedMessage id="IdentityVerificationForm.genericFailure" />}

              <PrimaryButton
                type="submit"
                inProgress={inProgress}
                ready={ready}
                disabled={invalid || inProgress}
              >
                <FormattedMessage id="IdentityVerificationForm.saveChanges" />
              </PrimaryButton>
            </div>
          ) : null}
        </Form>
      )}
    />
  );
};

IdentityVerificationFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inProgress: false,
  ready: false,
};

IdentityVerificationFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  inProgress: bool,
  ready: bool,
  error: propTypes.error,
};

const IdentityVerificationForm = IdentityVerificationFormComponent;

IdentityVerificationForm.displayName = 'IdentityVerificationForm';

export default IdentityVerificationForm;
